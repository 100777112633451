<template>
  <div class="success__popup-root">
    <div class="success__popup-bg" />
    <div class="success__popup">
      <div
        v-if="!internalOptions"
        class="success__title"
      >
        {{ $t('successCustomPopup.title')}}
      </div>
      <div
        v-if="internalOptions"
        class="success__title"
      >
        {{ internalOptions?.title }}
      </div>
      <div
        v-if="internalOptions?.message"
        class="success-popup__message-block"
      >
        <span
          class="success-popup__message"
        >
          {{ internalOptions.message }}
        </span>
      </div>
      <button class="success__submit-button btn primary" @click="closeSuccessPopup"> {{ $t('successCustomPopup.btnText') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { successPopupStore } from "~/stores/success-popup-store";
const { $i18n, $toast } = useNuxtApp();
const { closeSuccessPopup, internalOptions } = successPopupStore();
</script>

<style scoped>
.success__popup-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 997;
  align-items: center;
  padding: 15% 0;
}
.success__popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  transition: 0.5s all;
}
.success__popup {
  background: white;
  width: 30%;
  border-radius: 24px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px #00000040;
  gap: 30px;
}
.success__title {
  color: #333333;
  font-size: 40px;
  font-weight: 600;
  line-height: 46.88px;
  text-align: center;
  padding-bottom: 2px;
}
.success-popup__message-block {
  display: flex;
  width: 100%;
  background-color: #F4F7FE;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.success-popup__message {
  color: var(--primary-dark);
  font-size: 18px;
  line-height: 100%;
  text-align: center;
}
.success__submit-button {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .success__popup {
    width: 50%;
  }
}
@media screen and (max-width: 859px) {
  .success__popup {
    width: 70%;
  }
}
@media screen and (max-width: 640px) {
  .success__popup {
    width: 90%;
  }
  .success__title {
    font-size: 25px;
    line-height: 27.38px;
    padding: 0;
  }
}
</style>
